import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import editAccount from "@/features/tenantManagement/api/editAccountService";
import { userFormStyles } from "@/features/tenantManagement/styles/userFormStyles";
import { User, UserFormType } from "@/shared/types/userInfoModels";
import { stringAvatar } from "@/shared/utils/formattingFunctions";

const validationSchema = Yup.object({
  firstName: Yup.string().max(50, "Name should not exceed 50 characters"),
  lastName: Yup.string().max(50, "Last Name should not be longer than 50 characters"),
  emailAddress: Yup.string().email("Need email!!!"),
  company: Yup.string().max(50, "Company should not exceed 50 characters"),
  officeLocation: Yup.string().max(50, "Region should not be longer than 50 characters"),
  group: Yup.string().max(50, "Team should not be longer than 50 characters"),
  jobTitle: Yup.string().max(50, "Job Title should not be longer than 50 characters"),
});

type ProfileProps = {
  userInfo: User;
};

export default function Profile({ userInfo }: ProfileProps) {
  const initialValues: UserFormType = {
    image: "",
    userId: userInfo.userId,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    fullName: userInfo.fullName,
    role: userInfo.role,
    contact: "", // phone number
    emailAddress: userInfo.emailAddress,
    officeLocation: userInfo.officeLocation,
    jobTitle: userInfo.jobTitle,
    company: userInfo.companyName,
    group: "eTECHNOLOGY",
    department: userInfo.department,
    companyName: userInfo.companyName,
  };

  const handleFormSubmit = async (values: UserFormType) => {
    try {
      await editAccount(values);
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(`Failed to edit account: ${err.message}`);
      } else {
        throw new Error("Failed to edit account: An unknown error occurred");
      }
    }
  };

  return (
    <Box sx={userFormStyles.box}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}>
        {({ values, handleChange, handleBlur, errors, touched, dirty }) => (
          <Form style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <Stack
              spacing={3}
              direction="row"
              alignItems="center"
              sx={userFormStyles.avatarStack}>
              <Avatar sx={userFormStyles.avatar}>
                <Typography sx={userFormStyles.avatarTypography}>
                  {userInfo.fullName !== "" ? stringAvatar(userInfo.fullName) : null}
                </Typography>
              </Avatar>

              <Stack direction="column" sx={userFormStyles.stack}>
                <TextField
                  name="firstName"
                  label="Name"
                  variant="filled"
                  sx={userFormStyles.textfield}
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstName && Boolean(errors.firstName)}
                />
                <TextField
                  name="lastName"
                  label="Surname"
                  variant="filled"
                  sx={userFormStyles.textfield}
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastName && Boolean(errors.lastName)}
                />
              </Stack>
            </Stack>
            <Stack direction="row" spacing={3}>
              <TextField
                name="company"
                label="Company"
                defaultValue="eCERTO"
                variant="filled"
                sx={userFormStyles.textfield}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.company && Boolean(errors.company)}
              />
              <TextField
                name="officeLocation"
                label="Office"
                variant="filled"
                sx={userFormStyles.textfield}
                value={values.officeLocation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.officeLocation && Boolean(errors.officeLocation)}
              />
            </Stack>
            <TextField
              name="group"
              label="Department"
              variant="filled"
              sx={userFormStyles.textfield}
              value={values.group}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.group && Boolean(errors.group)}
            />
            <TextField
              name="jobTitle"
              label="Job title"
              variant="filled"
              sx={userFormStyles.textfield}
              value={values.jobTitle}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.jobTitle && Boolean(errors.jobTitle)}
            />

            <Box sx={userFormStyles.buttonBox}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={!dirty}
                sx={userFormStyles.saveButton}>
                <Typography variant="button">Save</Typography>
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
