import msalInstance from "@/shared/config/authConfig";

const auth = async () => {
  try {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      scopes: [
        "openid", // Required scope for authentication
        "offline_access", // Allows refresh tokens if needed
        "https://ecertointegratidev.onmicrosoft.com/8872ab2c-87a3-46e3-80ae-87dede802c65/User.ReadWrite.All",
        "https://ecertointegratidev.onmicrosoft.com/8872ab2c-87a3-46e3-80ae-87dede802c65/User.Read.All",
        "https://ecertointegratidev.onmicrosoft.com/8872ab2c-87a3-46e3-80ae-87dede802c65/User.Read",
        "https://ecertointegratidev.onmicrosoft.com/8872ab2c-87a3-46e3-80ae-87dede802c65/User.DeleteRestore.All",
        "https://ecertointegratidev.onmicrosoft.com/8872ab2c-87a3-46e3-80ae-87dede802c65/Directory.ReadWrite.All",
        "https://ecertointegratidev.onmicrosoft.com/8872ab2c-87a3-46e3-80ae-87dede802c65/AppRoleAssignment.ReadWrite.All",
        "https://ecertointegratidev.onmicrosoft.com/8872ab2c-87a3-46e3-80ae-87dede802c65/Directory.Read.All", // Custom API scope
      ],
      account,
    });
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers,
    };
    return {
      options,
      response,
    };
  } catch (error: unknown) {
    // @todo Handle Error Below
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
};

export default auth;
