import { FormikProps } from "formik";
import { useState, useRef } from "react";

import InviteUserForm from "@/features/tenantManagement/components/userManagement/forms/InviteUserForm";
import addUser from "@/features/tenantManagement/services/addUser";
import { InviteUserFormModel } from "@/features/tenantManagement/types/inviteUserFormModel";
import { PrimaryButton } from "@/shared/components/buttons";
import { RDialog } from "@/shared/components/forms";
import getInvitedUserFromGraph from "@/shared/store/authThunks/getNewUser";

export default function InviteUsers() {
  const [isModalOpen, setModalOpen] = useState(false);
  const newFormikRef = useRef<FormikProps<InviteUserFormModel>>(null);
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmitForm = async () => {
    if (isModalOpen && newFormikRef.current) {
      try {
        await newFormikRef.current.submitForm(); // Use await
        setModalOpen(false);
      } catch (error) {
        throw new Error("Error submitting form:");
      }
    }
  };

  const handleConfirm = async (values: InviteUserFormModel) => {
    try {
      await addUser(values); // Add await here to handle the Promise
      void getInvitedUserFromGraph(values.email, values.companyName);
      handleClose(); // Close the dialog after the user is added
      // getUserByEmail()
    } catch (error) {
      throw new Error("Error adding user:");
    }
  };

  return (
    <>
      <PrimaryButton onClick={handleOpen}>INVITE USERS</PrimaryButton>
      <RDialog
        isOpen={isModalOpen}
        title="INVITE NEW USER"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleSubmitForm}
        // confirmButtonCallback={handleDialogConfirm}
        confirmButtonText="INVITE"
        confirmButtonType="primary"
        size="medium">
        <InviteUserForm onSubmit={() => void handleConfirm} innerRef={newFormikRef} />
      </RDialog>
    </>
  );
}
