import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";

import { addUserFormStyles } from "@/features/tenantManagement/styles/addUserFormStyles";
import { InviteUserFormModel } from "@/features/tenantManagement/types/inviteUserFormModel";
import { userValidationSchema } from "@/features/tenantManagement/utils/validations/userValidationSchema";
import RListButton from "@/shared/components/buttons/RListButton";
import { Roles } from "@/shared/enums/roles";

type InviteUserFormProps = {
  onSubmit: (values: InviteUserFormModel) => void;
  innerRef: RefObject<FormikProps<InviteUserFormModel>>;
};

export default function InviteUserForm({ onSubmit, innerRef }: InviteUserFormProps) {
  const roles = Object.values(Roles).map((role) => ({
    value: role,
    label: role,
  }));

  return (
    <Formik<InviteUserFormModel>
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        jobTitle: "",
        role: Roles.Member,
        companyName: "",
        department: "",
        office: "",
      }}
      validationSchema={userValidationSchema}
      innerRef={innerRef}
      onSubmit={(values) => {
        try {
          onSubmit(values);
        } catch (err) {
          if (err instanceof Error) {
            throw new Error(`Failed to confirm user: ${err.message}`);
          } else {
            throw new Error("Failed to confirm user: An unknown error occurred");
          }
        }
      }}>
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
          <Form>
            <TextField
              required
              name="firstName"
              label="FirstName"
              variant="filled"
              sx={addUserFormStyles.textfield}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && Boolean(errors.firstName)}
              fullWidth
            />
            <TextField
              required
              name="lastName"
              label="LastName"
              variant="filled"
              sx={addUserFormStyles.textfield}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && Boolean(errors.lastName)}
              fullWidth
            />

            <TextField
              required
              name="email"
              label="Email"
              variant="filled"
              sx={addUserFormStyles.textfield}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              fullWidth
            />
            <TextField
              required
              name="jobTitle"
              label="JobTitle"
              variant="filled"
              sx={addUserFormStyles.textfield}
              value={values.jobTitle}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.jobTitle && Boolean(errors.jobTitle)}
              fullWidth
            />
            <TextField
              required
              select
              name="role"
              label="Role"
              variant="filled"
              sx={addUserFormStyles.textfield}
              value={values.role}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.role && Boolean(errors.role)}
              helperText={touched.role && errors.role}
              fullWidth>
              {roles.map(({ value, label }) => (
                <RListButton key={value} value={value} isMenuItem>
                  {label}
                </RListButton>
              ))}
            </TextField>
            <TextField
              required
              name="companyName"
              label="CompanyName"
              variant="filled"
              sx={addUserFormStyles.textfield}
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.companyName && Boolean(errors.companyName)}
              fullWidth
            />
            <TextField
              required
              name="department"
              label="Department"
              variant="filled"
              sx={addUserFormStyles.textfield}
              value={values.department}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.department && Boolean(errors.department)}
              fullWidth
            />
            <TextField
              required
              name="office"
              label="Office"
              variant="filled"
              sx={addUserFormStyles.textfield}
              value={values.office}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.office && Boolean(errors.office)}
              fullWidth
            />
          </Form>
        </Box>
      )}
    </Formik>
  );
}
