import { AxiosResponse, AxiosError } from "axios";

import { USER_IDENTITY_ENDPOINTS } from "@/shared/api/endpoints";
import apiRequests from "@/shared/config/axiosConfig";
import { User } from "@/shared/types/userInfoModels";

export default async function removeUser(user: User): Promise<User> {
  try {
    const url = USER_IDENTITY_ENDPOINTS.DELETE_USER.replace("{userId}", user.userId);
    // Send the delete request with the URL containing the userId
    const response: AxiosResponse<User> = await apiRequests.delete(url);
    const { data, status } = response;
    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError<{ message: string }>;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}
