import { Box, CircularProgress } from "@mui/material";
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  useMaterialReactTable,
} from "material-react-table";
import { useState } from "react";

import UserManagementActions from "@/features/tenantManagement/components/userManagement/UserManagementActions";
import UserManagementColumns from "@/features/tenantManagement/components/userManagement/UserManagementColumns";
import UserManagementToolbar from "@/features/tenantManagement/components/userManagement/UserManagementToolbar";
import { UserManagementModel } from "@/features/tenantManagement/types/userManagementModel";
import { User } from "@/shared/types/userInfoModels";

type UMProps = {
  userInfo: User[];
};
export default function UserManagement({ userInfo }: UMProps) {
  // Initialize row selection state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  // Users data state with the provided userInfo
  // Later we might use `setUsers` to update the user data
  // Map userInfo array to users state with the correct format
  const [users, setUsers] = useState<UserManagementModel[]>(
    userInfo.map((user) => ({
      image: user.image || "Image Placeholder",
      userId: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: `${user.firstName} ${user.lastName}` || "N/A",
      name: `${user.firstName} ${user.lastName}` || "N/A",
      officeLocation: user.officeLocation,
      jobTitle: user.jobTitle,
      department: user.department,
      companyName: user.companyName,
      emailAddress: user.emailAddress,
      contact: user.emailAddress || "N/A",
      role: user.role || "N/A",
      group: "eTECHNOLOGY",
    }))
  );

  const handleUserRoleUpdated = (u: User, newRole: string) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.userId === u.userId
          ? { ...user, role: newRole } // Update the role of the specific user
          : user
      )
    );
  };

  // Columns using the existing UserManagementColumns
  const columns = UserManagementColumns();
  // Table instance using the useMaterialReactTable hook
  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data: users,
    editDisplayMode: "cell",
    enableBottomToolbar: true,
    enableColumnActions: false,
    enableColumnFilters: true,
    enableColumnFilterModes: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableEditing: true,
    enableFullScreenToggle: false,
    enableGlobalFilterModes: true,
    enablePagination: true,
    enableRowActions: true,
    enableSorting: true,
    enableStickyHeader: true,
    enableTopToolbar: false,
    initialState: {
      columnOrder: [
        "image",
        "name",
        // "group",
        "role",
        "mrt-row-actions", // Place the action column at the end
      ],
    },
    layoutMode: "grid",
    muiBottomToolbarProps: { sx: () => ({ background: "#fff" }) },
    muiTableFooterCellProps: { sx: () => ({ background: "#fff" }) },
    // Header styles
    muiTableHeadCellProps: {
      sx: () => ({
        background: "var(--primary-color)",
        color: "#fff",
        fontSize: "12px",
      }),
    },
    muiTableProps: {
      sx: {
        tableLayout: "fixed",
        overflowX: "hidden",
      },
    },
    muiTableBodyCellProps: {
      align: "center",
      sx: { backgroundColor: "#fff", padding: "1rem", height: "5rem" },
    },
    muiTableContainerProps: {
      sx: {
        "&::-webkit-scrollbar": {
          backgroundColor: "#F5F5F5",
          borderRadius: "10px",
          height: "12px !important",
          width: "8px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#ccc",
          borderRadius: "10px",
          "&:active": {
            backgroundColor: "#808080",
          },
          "&:hover": {
            backgroundColor: "#B3B3B3",
          },
        },
        height: "32.5rem",
        overflow: "auto",
        overflowY: "auto",
        backgroundColor: "#fff",
      },
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 50,
        enablePinning: false,
        muiTableHeadCellProps: {
          sx: {
            minWidth: "5rem",
            maxWidth: "5rem",
            backgroundColor: "#003057 !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            minWidth: "5rem",
            maxWidth: "5rem",
            backgroundColor: "#fff !important",
          },
        },
      },
    },
    muiTopToolbarProps: { sx: () => ({ background: "#fff" }) },
    onRowSelectionChange: setRowSelection,
    paginateExpandedRows: false,
    positionToolbarAlertBanner: "none",
    renderRowActions: ({ row }) => (
      <UserManagementActions
        key={row.id}
        user={row.original}
        onUserRemoved={(userId: string) =>
          setUsers((prevUsers) => prevUsers.filter((user) => user.userId !== userId))
        }
        onUserRoleUpdated={handleUserRoleUpdated}
      />
    ),
    state: {
      rowSelection,
    },
  });
  // If there is no data, show a loading spinner
  if (users === undefined) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        height="100%"
        width="100%">
        <CircularProgress color="primary" size={50} />
      </Box>
    );
  }
  return (
    <Box sx={{ marginBottom: "1rem" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <UserManagementToolbar />
      </Box>
      <MaterialReactTable
        table={table} // Table instance directly to MaterialReactTable component
      />
    </Box>
  );
}
