import { User } from "@/shared/types/userInfoModels";

const defaultUser: User = {
  image: "",
  userId: "",
  emailAddress: "",
  firstName: "",
  contact: "",
  lastName: "",
  fullName: "",
  role: "",
  officeLocation: "",
  jobTitle: "",
  department: "",
  companyName: "",
};

export const getUserInfo = (): User => {
  const storedUserInfo = localStorage.getItem("userInfo");

  if (storedUserInfo) {
    const userInfoObj = JSON.parse(storedUserInfo) as User;
    return userInfoObj;
  }

  return defaultUser;
};

export const getAllUsersInfo = (): User[] => {
  const storedUsersInfo = localStorage.getItem("allUsersInfo");

  if (storedUsersInfo) {
    const usersInfoArray = JSON.parse(storedUsersInfo) as User[];
    return usersInfoArray;
  }

  return []; // Return an empty array if no data is found
};

export const getNewUserInfo = (): User => {
  const storedNewUserInfo = localStorage.getItem("newUserInfo");

  if (storedNewUserInfo) {
    const newUserInfoObj = JSON.parse(storedNewUserInfo) as User;
    return newUserInfoObj;
  }

  return defaultUser; // Return an empty array if no data is found
};
