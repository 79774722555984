import * as yup from "yup";

import { Roles } from "@/shared/enums/roles";

export const userValidationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email("Please enter a valid email").required("Email is required"),
  jobTitle: yup.string().required("Job title is required"),
  role: yup
    .string()
    .oneOf(Object.values(Roles), "Please select a valid role")
    .required("Role is required"),
  companyName: yup.string().required("Company name is required"),
  department: yup.string().required("Department is required"),
  office: yup.string().required("Office location is required"),
});
