import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from "@mui/icons-material/Key";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { useState } from "react";

// import ChangeRoleButton from "@/features/tenantManagement/components/userManagement/ChangeRoleButton";
import RemoveUsersButton from "@/features/tenantManagement/components/userManagement/RemoveUsersButton";
// import changeUserRole from "@/features/tenantManagement/services/changeUserRole";
import removeUser from "@/features/tenantManagement/services/removeUser";
import RListButton from "@/shared/components/buttons/RListButton";
import RMenu from "@/shared/components/RMenu";
import getAllUsersFromGraph from "@/shared/store/authThunks/getAllUsers";
import { useAppDispatch } from "@/shared/store/hooks";
import { getUserInfo } from "@/shared/store/userInfo";
import { User } from "@/shared/types/userInfoModels";
import { useAuth } from "@/shared/utils/auth";

type UserManagementActionsProps = {
  user: User;
  onUserRemoved: (userId: string) => void; // Notify parent when a user is removed
  onUserRoleUpdated: (user: User, newRole: string) => void;
};
export default function UserManagementActions({
  user,
  onUserRemoved,
  // onUserRoleUpdated,
}: UserManagementActionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const open = Boolean(anchorEl);
  const userInfo = getUserInfo();

  const dispatch = useAppDispatch();
  const { logOut } = useAuth();

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOpenUMS = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUMS = () => {
    setAnchorEl(null);
  };

  const handleRemove = async () => {
    try {
      // Call the removeUser function
      await removeUser(user);

      onUserRemoved(user.userId); // Notify parent to update the table

      // Check if the current user is being removed
      if (user.userId === userInfo.userId) {
        void logOut(); // Log out if the current user is being deleted
      } else {
        await dispatch(getAllUsersFromGraph()); // Refresh the user list for others
      }

      // Close menus/dialogs
      handleCloseUMS();
      handleClose();
    } catch (error) {
      throw new Error("An unexpected error occurred");
    }
  };

  // const handleRoleChange = async (newRole: string) => {
  //   // Add API call or state update logic here
  // @todo we should create a new UpdateUserRoleModel object and populate with information. Then send the object itself to the changeUserRole function.
  //   await changeUserRole({ user, newRole });
  //   onUserRoleUpdated(user, newRole); // Notify parent to update the table
  // };

  return (
    <Box>
      <IconButton onClick={handleOpenUMS}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseUMS}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <RMenu>
          <RListButton icon={<KeyIcon />} isMenuItem></RListButton>
          <Divider />
          <RListButton icon={<DeleteIcon />} type="error" isMenuItem>
            <RemoveUsersButton
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleRemove={() => handleRemove}
              isModalOpen={isModalOpen}
              user={user}
            />
          </RListButton>
        </RMenu>
      </Menu>
    </Box>
  );
}
