/* eslint-disable import/no-restricted-paths -- Allow importing UserManagementLayout in this file */
import { Modal } from "@mui/material";

import Profile from "@/features/tenantManagement/components/Profile";
import UserManagement from "@/features/tenantManagement/components/userManagement/UserManagement";
import TenantManagementLayout from "@/layouts/TenantManagementLayout";
import { useTenantManagement } from "@/shared/context/tenantManagementCtx/useManagementTenant";
import TenantManagementView from "@/shared/enums/tenantManagement";
import { getAllUsersInfo, getUserInfo } from "@/shared/store/userInfo";

export default function TenantManagement() {
  const userInfo = getUserInfo();
  const usersInfo = getAllUsersInfo();
  const { activeContent, isModalOpen, closeModal } = useTenantManagement();
  if (!userInfo || !usersInfo) {
    throw new Error("Failed to fetch user information");
    return null; // or render an error message/component
  }

  return (
    <Modal open={isModalOpen} onClose={closeModal}>
      <TenantManagementLayout user={userInfo}>
        {activeContent === TenantManagementView.Profile && (
          <Profile userInfo={userInfo} />
        )}
        {activeContent === TenantManagementView.ManageUsers && (
          <UserManagement userInfo={usersInfo} />
        )}
      </TenantManagementLayout>
    </Modal>
  );
}
