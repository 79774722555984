import { Dispatch } from "@reduxjs/toolkit";

import { graphConfig } from "@/shared/config/authConfig";
import { setUser } from "@/shared/store";
import auth from "@/shared/store/authThunks/auth";
import { User } from "@/shared/types/userInfoModels";

// Define the structure of the expected JSON response
export type GraphResponse = {
  id?: string;
  email?: string;
  userPrincipalName?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  role?: {
    id: string;
    roleName: string;
  };
  office?: string;
  jobTitle?: string;
  department?: string;
  companyName?: string;
};

// type IdTokenClaimsType = {
//   roles: string[];
// };

// Thunk
export default function getUserFromGraph() {
  return async (dispatch: Dispatch) => {
    try {
      // Wait for the auth() function to resolve
      const authResult = await auth();

      // Check if authResult is undefined
      if (!authResult) {
        throw new Error("Authentication failed: No auth result returned.");
      }

      const { options, response } = authResult;

      fetch(
        graphConfig.userIdentityEndpoint.replace("{userId}", response.uniqueId),
        options
      )
        .then(async (res) => {
          if (!res.ok) {
            // Throw an error if the status is not 200 OK
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          const json = (await res.json()) as { result: GraphResponse };

          // Access data from the result property
          const userData = json.result;
          // const idTokenClaims = response.idTokenClaims as IdTokenClaimsType;
          const userInfoObj: User = {
            image: "",
            userId: userData.id ?? "",
            emailAddress: userData.email ?? userData.userPrincipalName ?? "",
            firstName: userData.firstName ?? "",
            lastName: userData.lastName ?? "",
            contact: "", //phone number
            fullName:
              userData.firstName && userData.lastName
                ? `${userData.firstName} ${userData.lastName}`
                : "",
            role: userData.role?.roleName ?? "",
            officeLocation: userData.office ?? "",
            jobTitle: userData.jobTitle ?? "",
            companyName: userData.companyName ?? "",
            department: userData.department ?? "",
          };
          localStorage.setItem("userInfo", JSON.stringify(userInfoObj));

          if (!userInfoObj.userId || !userInfoObj.emailAddress) {
            throw new Error("User ID and email are required fields.");
          }

          dispatch(setUser(userInfoObj));
        })
        .catch((error) => {
          if (error instanceof Error) {
            throw new Error(error.message);
          } else {
            throw new Error("An unknown error occurred");
          }
        });
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("An unknown error occurred");
      }
    }
  };
}
