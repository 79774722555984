import { Dispatch } from "@reduxjs/toolkit";

import { graphConfig } from "@/shared/config/authConfig";
import { setUsers } from "@/shared/store";
import auth from "@/shared/store/authThunks/auth";
import { GraphResponse } from "@/shared/store/authThunks/getUser";
import { User } from "@/shared/types/userInfoModels";

type GraphResponseList = GraphResponse[];

export default function getAllUsersFromGraph() {
  return async (dispatch: Dispatch) => {
    try {
      // Wait for the auth() function to resolve
      const authResult = await auth();

      // Check if authResult is undefined
      if (!authResult) {
        throw new Error("Authentication failed: No auth result returned.");
      }

      const { options } = authResult;

      fetch(graphConfig.allUsersIdentityEndpoint, options)
        .then(async (res) => {
          if (!res.ok) {
            // Throw an error if the status is not 200 OK
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          const json = (await res.json()) as { result: GraphResponseList };

          // Access data from the result property
          // const idTokenClaims = response.idTokenClaims as IdTokenClaimsType;
          // Map the response data to an array of User objects
          const usersData = json.result.map((user) => ({
            image: "",
            userId: user.id ?? "",
            emailAddress: user.email ?? user.userPrincipalName ?? "",
            firstName: user.firstName ?? "",
            lastName: user.lastName ?? "",
            fullName:
              user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : "",
            role: user.role?.roleName ?? "",
            officeLocation: user.office ?? "",
            jobTitle: user.jobTitle ?? "",
            companyName: user.companyName ?? "",
            department: user.department ?? "",
          })) as User[];

          // Dispatch the setUsers action with the list of users
          dispatch(setUsers(usersData));
          localStorage.setItem("allUsersInfo", JSON.stringify(usersData));

          dispatch(setUsers(usersData));
        })
        .catch((error) => {
          if (error instanceof Error) {
            throw new Error(error.message);
          } else {
            throw new Error("An unknown error occurred");
          }
        });
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("An unknown error occurred");
      }
    }
  };
}
