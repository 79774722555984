import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { User } from "@/shared/types/userInfoModels";

type UserState = {
  user: User | null;
  users: User[]; // To store the list of users
  newUser: User | null;
};

const initialState: UserState = {
  user: null,
  users: [],
  newUser: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
    setNewUser: (state, action: PayloadAction<User | null>) => {
      state.newUser = action.payload;
    },
  },
});

export const { setUser, clearUser, setUsers, setNewUser } = userSlice.actions;

export default userSlice;
