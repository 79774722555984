import { Typography } from "@mui/material";

import { RDialog } from "@/shared/components/forms";
import { User } from "@/shared/types/userInfoModels";

type RemoveUserProps = {
  handleRemove: () => void;
  user: User;
  handleOpen: () => void;
  handleClose: () => void;
  isModalOpen: boolean;
};

export default function RemoveUsersButton({
  handleRemove,
  user,
  handleClose,
  handleOpen,
  isModalOpen,
}: RemoveUserProps) {
  return (
    <>
      <Typography onClick={handleOpen} sx={{ fontSize: "1.25rem" }}>
        Remove user
      </Typography>
      <RDialog
        isOpen={isModalOpen}
        title="DELETE USER"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleRemove}
        confirmButtonText="Remove"
        confirmButtonType="error"
        size="small">
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Are you sure you want to delete <br />
          <strong>{user.fullName}</strong>?
        </Typography>
      </RDialog>
    </>
  );
}
