export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL as string;

type SharedApiEndpoints = {
  ADD_PROJECT_DELIVERABLES: string;
  GET_CONCEPT_WBS: string;
};

export const SHARED_API_ENDPOINTS: SharedApiEndpoints = {
  GET_CONCEPT_WBS: "/api/projects/getProjectWBS/{projectId}",
  ADD_PROJECT_DELIVERABLES: "/api/deliverables", // Used in Step 1 Scope and Step 2 Schedule
};

//@todo This endpoint should be in the app > pages > auth folder because is not shared.
//@todo This means we also have to move the authConfig and authThunks to that folder.
//@todo Regarding the Base URL, should we leave it like this or integrate with Axios like our API URL.
export type UserIdentityApiEndpoints = {
  UPDATE_USER_IDENTITY_WITH_GOOGLE_ID: string;
  GET_USER_PROFILE: string;
  ADD_USER: string;
  GET_All_USERS: string;
  DELETE_USER: string;
  GET_USER_BY_EMAIL_AND_COMPANY_NAME: string;
  EDIT_ROLE: string;
};

export const USER_MANAGEMENT_API_BASE_URL = import.meta.env
  .VITE_UMS_API_BASE_URL as string;

export const USER_IDENTITY_ENDPOINTS: UserIdentityApiEndpoints = {
  GET_USER_PROFILE: "/api/Users/{userId}/profile",
  GET_All_USERS: "/api/users/all",
  ADD_USER: "/api/Users",
  DELETE_USER: "/api/users/{userId}",
  GET_USER_BY_EMAIL_AND_COMPANY_NAME: "/api/users/by-email-and-company",
  UPDATE_USER_IDENTITY_WITH_GOOGLE_ID: "/api/Users/linkGoogleIdentity",
  EDIT_ROLE: "/api/users/{userId}/role/editRoleAssignment",
};
